/* FLIP CARD */
/* Container for the flip effect and perspective */
.flip-card {
  perspective: 1000px;
  /* Change the value as you like */
}

/* Flip card inner wrapper to handle the flip */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  font-family: Arial, Helvetica, sans-serif;

  .flip_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    width: 30px;
    height: 30px;
    display: none;

    img {
      object-fit: contain;
    }

  }

  &:hover .flip_icon {
    display: block;
  }
}

.flip-card.is-flipped .flip_icon {
  z-index: -1;
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;

  /* Hide the back side when facing away */
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
  padding: 10px;
  overflow: scroll;
}

/* Flip the inner wrapper on hover */
.flip-card.is-flipped .flip-card-inner {
  transform: rotateY(180deg);
}


/* Style the back side */
.flip-card-back.horizontal {
  transform: rotateX(180deg);
  /* Flip the back side by default */
}

/* Flip the inner wrapper on hover */
.flip-card.is-flipped .flip-card-inner.horizontal {
  transform: rotateX(180deg);
}

/* Optional: Set size of the flip card */
.flip-card {
  width: 100%;
  /* Adjust as needed */
  height: 100%;
  /* Adjust as needed */
}

.flip-card.idle.over .flip-card-inner {
  animation: wiggle 0.3s ease;
}

@keyframes wiggle {
  0% {
    transform: rotateY(0);
  }

  25% {
    transform: rotateY(2deg);
  }

  50% {
    transform: rotateY(0);
  }

  75% {
    transform: rotateY(-2deg);
  }

  100% {
    transform: rotateY(0);
  }
}

.flip-card.idle.horizontal.over .flip-card-inner {
  animation: hwiggle 0.3s ease;
}

@keyframes hwiggle {
  0% {
    transform: rotateX(0);
  }

  25% {
    transform: rotateX(2deg);
  }

  50% {
    transform: rotateX(0);
  }

  75% {
    transform: rotateX(-2deg);
  }

  100% {
    transform: rotateX(0);
  }
}
