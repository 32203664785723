.flip-card {
  perspective: 1000px;
}

.flip-card-inner {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  transition: transform .6s;
  position: relative;
}

.flip-card-inner .flip_icon {
  z-index: 10;
  width: 30px;
  height: 30px;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.flip-card-inner .flip_icon img {
  object-fit: contain;
}

.flip-card-inner:hover .flip_icon {
  display: block;
}

.flip-card.is-flipped .flip_icon {
  z-index: -1;
}

.flip-card-front, .flip-card-back {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.flip-card-back {
  padding: 10px;
  overflow: scroll;
  transform: rotateY(180deg);
}

.flip-card.is-flipped .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-back.horizontal, .flip-card.is-flipped .flip-card-inner.horizontal {
  transform: rotateX(180deg);
}

.flip-card {
  width: 100%;
  height: 100%;
}

.flip-card.idle.over .flip-card-inner {
  animation: .3s wiggle;
}

@keyframes wiggle {
  0% {
    transform: rotateY(0);
  }

  25% {
    transform: rotateY(2deg);
  }

  50% {
    transform: rotateY(0);
  }

  75% {
    transform: rotateY(-2deg);
  }

  100% {
    transform: rotateY(0);
  }
}

.flip-card.idle.horizontal.over .flip-card-inner {
  animation: .3s hwiggle;
}

@keyframes hwiggle {
  0% {
    transform: rotateX(0);
  }

  25% {
    transform: rotateX(2deg);
  }

  50% {
    transform: rotateX(0);
  }

  75% {
    transform: rotateX(-2deg);
  }

  100% {
    transform: rotateX(0);
  }
}

/*# sourceMappingURL=index.ff77a4a9.css.map */
